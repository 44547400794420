<template>
	<section class="lp" id="js_pagetop">
		<h1 class="lp__header" id="js_observer">
			<img src="~@/assets/img/lp_spring2023/title.png" alt="毎日チェックで体調管理をサポート 春の5Kに要注意！ お天気健康クイズ">
			<p class="lp__header--logo"><span>presented by</span><img src="~@/assets/img/lp_spring2023/logo.png" alt="頭痛ーる"></p>
		</h1>

		<!-- イントロ -->
		<p class="lp__intro">天気や気温が変わりやすく、新生活などで環境の変化が大きい4月は体調を崩したり、疲れがたまりやすい時期。その要因が何かを<em>「お天気健康クイズ」</em>で体調管理や健康維持のために知って得する知識を楽しみながらチェックしましょう。さらに春の不調の要因<em>「春の5K」</em>の解説や、対策に役立つアプリ機能もご紹介します！</p>
		<!-- /イントロ -->

		<div class="lp__inner">
			<!-- 目次 -->
			<nav class="lp__nav">
				<h2 class="lp__nav--title">春の不調対策メニュー</h2>
				<ul class="lp__nav--list">
					<li><a href="javascript:void(0);" @click="onScroll('js_quiz')"><img src="~@/assets/img/lp_spring2023/navi01.png" alt="STEP1 毎日更新きょうのクイズ"></a></li>
					<li><a href="javascript:void(0);" @click="onScroll('js_5k')"><img src="~@/assets/img/lp_spring2023/navi02.png" alt="STEP2 乾燥・花粉… 春の5Kとは？"></a></li>
					<li><a href="javascript:void(0);" @click="onScroll('js_inspection')"><img src="~@/assets/img/lp_spring2023/navi03.png" alt="STEP3 チャレンジ お天気健康検定"></a></li>
				</ul>
			</nav>
			<!-- /目次 -->

			<!-- クイズ -->
			<section class="lp__contents is-quiz" id="js_quiz">
				<h2 class="lp__contents--title">
					<span>4/1より毎日更新</span>
					<em>お天気健康クイズ</em>
				</h2>

				<section class="lp__quiz">
					<h3 class="lp__quiz--title">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 22 22">
							<defs>
								<clipPath id="clip-path">
									<rect data-name="長方形 3181" width="11.698" height="11.698" fill="#fff"/>
								</clipPath>
							</defs>
							<g transform="translate(-118 -800)">
								<circle cx="11" cy="11" r="11" transform="translate(118 800)" fill="#be954e"/>
								<g transform="translate(123.151 805.151)">
									<g clip-path="url(#clip-path)">
									<path d="M129.891.4a1.361,1.361,0,0,0-1.924,0l-.82.821,2.8,2.8.82-.82a1.363,1.363,0,0,0,0-1.925Z" transform="translate(-119.468 0)" fill="#fff"/>
									<path d="M0,47.9v2.8H2.8l6.391-6.391-2.8-2.8Z" transform="translate(0 -38.999)" fill="#fff"/>
									</g>
								</g>
							</g>
						</svg>
						<span>きょうの問題</span>
					</h3>
					<p class="lp__quiz--text">{{ computedTodaysQuiz.q }}</p>
					<ul class="lp__quiz--list">
						<li
							@click="answer = 1"
							:class="[
								'lp__quiz--item',
								{ 'is-check': answer === 1 }
							]"
						>
							<em>A</em>
							<p>{{ computedTodaysQuiz.choice[0] }}</p>
						</li>
						<li
							@click="answer = 2"
							:class="[
								'lp__quiz--item',
								{ 'is-check': answer === 2 }
							]"
						>
							<em>B</em>
							<p>{{ computedTodaysQuiz.choice[1] }}</p>
						</li>
						<li
							@click="answer = 3"
							:class="[
								'lp__quiz--item',
								{ 'is-check': answer === 3 }
							]"
						>
							<em>C</em>
							<p>{{ computedTodaysQuiz.choice[2] }}</p>
						</li>
					</ul>
					<a
						href="javascript:void(0);"
						@click="isVisiableAnswerModal = true"
						data-gtm-event-cat-label="春の5K特集ページ2023クイズ回答"
						data-gtm-event-act-label="trackingLpSpring2023Quiz"
						:class="[
							'lp__quiz--button',
							'gtm-link_click_count01',
							{ 'is-active': answer !== 0 }
						]"
					>解答する</a>
				</section>
			</section>
			<!-- /クイズ -->

			<!-- 春の5K -->
			<section class="lp__contents is-explanation" id="js_5k">
				<h2 class="lp__contents--title">
					<span>要注意！</span>
					<em>春の5Kとは？</em>
				</h2>
				<p class="lp__text lp__contents--text">春に要注意なことの頭文字をアルファベットにすると、全てKで始まる次の５つ。春の５Kは、気圧のアップダウンによる影響と合わさることで、頭痛など体調が悪化することもあるので、気を付けましょう！</p>

				<section class="lp__5k">
					<h3 class="lp__5k--title">▼各項目タップしてCHECK！▼</h3>
					<ul class="lp__5k--list">
						<li class="lp__5k--item">
							<a href="javascript:void(0);" @click="clickModalLink(1, $event)" class="lp__5k--button gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023乾燥ボタン" data-gtm-event-act-label="trackingLpSpring2023Kansou"><img src="~@/assets/img/lp_spring2023/btn_kansou.png" alt="乾燥 Kansou"></a>
						</li>
						<li class="lp__5k--item">
							<a href="javascript:void(0);" @click="clickModalLink(2, $event)" class="lp__5k--button gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023花粉ボタン" data-gtm-event-act-label="trackingLpSpring2023Kafun"><img src="~@/assets/img/lp_spring2023/btn_kafun.png" alt="花粉 Kafun"></a>
						</li>
						<li class="lp__5k--item">
							<a href="javascript:void(0);" @click="clickModalLink(3, $event)" class="lp__5k--button gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023強風ボタン" data-gtm-event-act-label="trackingLpSpring2023Kyoufuu"><img src="~@/assets/img/lp_spring2023/btn_kyoufuu.png" alt="強風 Kyoufuu"></a>
						</li>
						<li class="lp__5k--item">
							<a href="javascript:void(0);" @click="clickModalLink(4, $event)" class="lp__5k--button gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023寒暖差ボタン" data-gtm-event-act-label="trackingLpSpring2023Kandansa"><img src="~@/assets/img/lp_spring2023/btn_kandansa.png" alt="寒暖差 Kandansa"></a>
						</li>
						<li class="lp__5k--item">
							<a href="javascript:void(0);" @click="clickModalLink(5, $event)" class="lp__5k--button gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023黄砂ボタン" data-gtm-event-act-label="trackingLpSpring2023Kousa"><img src="~@/assets/img/lp_spring2023/btn_kousa.png" alt="黄砂 Kousa"></a>
						</li>
					</ul>
					<div class="lp__5k--outro">
						<img src="~@/assets/img/lp_spring2023/hiroshi.png" alt="ヒロシ">
						<p><span>春の５Kの対策を<br>チェックじゃ！</span></p>
					</div>
				</section>
			</section>
			<!-- /春の5K-->

			<!-- お天気検定 -->
			<section class="lp__contents is-inspection" id="js_inspection">
				<h2 class="lp__contents--title">
					<em>step1と2で学んだ知識を腕試し！</em>
				</h2>

				<section class="lp__inspection">
					<h3 class="lp__inspection--title"><img src="~@/assets/img/quiz_spring/title.png" alt="春の体調管理に役に立つ知識をチェック！ 春のお天気健康検定"></h3>
					<p class="lp__inspection--text">春の体調管理やより良い暮らしに役立つ知識を腕試し！厳選10問のクイズに答えて、あなたの健康天気レベルを判定します！</p>
					<div class="lp__inspection--quiz">
						<h4 class="lp__inspection--heading">Q.1</h4>
						<p class="lp__inspection--question">春の不調の原因になる「春の５K」とは、乾燥、花粉、強風、黄砂、もう一つは何？</p>
						<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=quizSpring` } })" class="lp__inspection--button gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023検定ボタン" data-gtm-event-act-label="trackingLpSpring2023Inspection">いますぐチャレンジ！</a>
					</div>
				</section>

				<section class="lp__calendar">
					<h3 class="lp__calendar--title"><span>限定版のカレンダーをプレゼント！</span></h3>
					<p class="lp__calendar--text">検定にチャレンジすると、春らしい風景と頭痛ーるズがこの特集限定でコラボしたスマホの壁紙に使える月の満ち欠けカレンダー画像をゲットできます！</p>
					<div class="lp__calendar--sample">
						<div class="lp__calendar--charactor">
							<p><span>マロも<br>欲しいニャ～</span></p>
							<img src="~@/assets/img/lp_spring2023/maro.png" alt="マロ">
						</div>
					</div>
				</section>
			</section>
			<!-- /お天気検定 -->

			<!-- 頭痛ーるTOPへ戻るボタン -->
			<nav class="lp__outro">
				<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="lp__outro--button">頭痛ーるTOPへ</a>
			</nav>
			<!-- /頭痛ーるTOPへ戻るボタン -->

			<!-- 上部に戻るボタン -->
			<nav class="lp__pagetop" id="js_pagetop_btn">
				<a href="javascript:void(0);" @click.prevent="onScroll('js_pagetop')" class="lp__pagetop--link">
					<img src="~@/assets/img/pagetop_btn01.png" alt="ページTOPにもどる">
				</a>
			</nav>
			<!-- /上部に戻るボタン -->
		</div>

		<!-- 下に吸着するアプリダウンロードボタン -->
		<aside class="sticky-bottom-appdownload">
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023_アプリDLボタン_ページ下部に吸着するボタンAndroid" data-gtm-event-act-label="trackingLpSpring2023_appDownload_sticky_android"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
			<a v-else href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023_アプリDLボタン_ページ下部に吸着するボタンiOS" data-gtm-event-act-label="trackingLpSpring2023_appDownload_sticky_iOS"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
		</aside>
		<!-- /下に吸着するアプリダウンロードボタン -->

		<!-- 春の5Kモーダル -->
		<v-ons-modal :visible="isVisibleModal" animation="fade" animationOptions="{ duration: 0.2, timing: 'ease-in' }" class="lp__modal">
			<aside class="lp__modal--inner">
				<div v-show="modalType === 1" class="lp__modal--body">
					<!-- slider -->
					<div class="slider">
						<div class="slider__container swiper-container js_swiper">
							<ul class="swiper-wrapper">
								<li class="swiper-slide">
									<i class="sprite kansou_1" />
								</li>
								<li class="swiper-slide">
									<i class="sprite kansou_2" />
								</li>
								<li class="swiper-slide">
									<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023_乾燥アプリDLボタンAndroid" data-gtm-event-act-label="trackingLpSpring023_kansou_appDownload_android"><i class="sprite app_dl" /></a>
									<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023_乾燥アプリDLボタンiOS" data-gtm-event-act-label="trackingLpSpring023_kansou_appDownload_iOS"><i class="sprite app_dl" /></a>
								</li>
							</ul>
						</div>
						<div class="slider__nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 2" class="lp__modal--body">
					<!-- slider -->
					<div class="slider">
						<div class="slider__container swiper-container js_swiper">
							<ul class="swiper-wrapper">
								<li class="swiper-slide">
									<i class="sprite kafun_1" />
								</li>
								<li class="swiper-slide">
									<i class="sprite kafun_2" />
								</li>
								<li class="swiper-slide">
									<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023_花粉アプリDLボタンAndroid" data-gtm-event-act-label="trackingLpSpring023_kafun_appDownload_android"><i class="sprite app_dl" /></a>
									<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023_花粉アプリDLボタンiOS" data-gtm-event-act-label="trackingLpSpring023_kafun_appDownload_iOS"><i class="sprite app_dl" /></a>
								</li>
							</ul>
						</div>
						<div class="slider__nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 3" class="lp__modal--body">
					<!-- slider -->
					<div class="slider">
						<div class="slider__container swiper-container js_swiper">
							<ul class="swiper-wrapper">
								<li class="swiper-slide">
									<i class="sprite kyoufuu_1" />
								</li>
								<li class="swiper-slide">
									<i class="sprite kyoufuu_2" />
								</li>
								<li class="swiper-slide">
									<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023_強風アプリDLボタンAndroid" data-gtm-event-act-label="trackingLpSpring023_kyoufuu_appDownload_android"><i class="sprite app_dl" /></a>
									<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023_強風アプリDLボタンiOS" data-gtm-event-act-label="trackingLpSpring023_kyoufuu_appDownload_iOS"><i class="sprite app_dl" /></a>
								</li>
							</ul>
						</div>
						<div class="slider__nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 4" class="lp__modal--body">
					<!-- slider -->
					<div class="slider">
						<div class="slider__container swiper-container js_swiper">
							<ul class="swiper-wrapper">
								<li class="swiper-slide">
									<i class="sprite kandansa_1" />
								</li>
								<li class="swiper-slide">
									<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=selfcheckTemperatureDifference` } })"><i class="sprite kandansa_2" /></a>
								</li>
								<li class="swiper-slide">
									<i class="sprite kandansa_3" />
								</li>
								<li class="swiper-slide">
									<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023_寒暖差アプリDLボタンAndroid" data-gtm-event-act-label="trackingLpSpring023_kandansa_appDownload_android"><i class="sprite app_dl" /></a>
									<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023_寒暖差アプリDLボタンiOS" data-gtm-event-act-label="trackingLpSpring023_kandansa_appDownload_iOS"><i class="sprite app_dl" /></a>
								</li>
							</ul>
						</div>
						<div class="slider__nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 5" class="lp__modal--body">
					<!-- slider -->
					<div class="slider">
						<div class="slider__container swiper-container js_swiper">
							<ul class="swiper-wrapper">
								<li class="swiper-slide">
									<i class="sprite kousa_1" />
								</li>
								<li class="swiper-slide">
									<i class="sprite kousa_2" />
								</li>
								<li class="swiper-slide">
									<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023_黄砂アプリDLボタンAndroid" data-gtm-event-act-label="trackingLpSpring023_kousa_appDownload_android"><i class="sprite app_dl" /></a>
									<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="gtm-link_click_count01" data-gtm-event-cat-label="春の5K特集ページ2023_黄砂アプリDLボタンiOS" data-gtm-event-act-label="trackingLpSpring023_kousa_appDownload_iOS"><i class="sprite app_dl" /></a>
								</li>
							</ul>
						</div>
						<div class="slider__nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div class="lp__modal--button">
					<a @click="clickModalLink(0, $event)">
						<ons-icon icon="md-close" />表示を閉じる
					</a>
				</div>
			</aside>
		</v-ons-modal>
		<!-- /春の5Kモーダル -->

		<!-- クイズ答えモーダル -->
		<transition name="fade">
			<aside v-if="isVisiableAnswerModal" class="lp__quiz-modal">
				<div class="lp__quiz-modal--inner">
					<div class="lp__quiz-modal--result">
						<h2 v-show="answer === computedTodaysQuiz.a" class="lp__quiz-modal--title"><img src="~@/assets/img/lp_spring2023/correct.png" alt="正解"></h2>
						<h2 v-show="answer !== computedTodaysQuiz.a" class="lp__quiz-modal--title"><img src="~@/assets/img/lp_spring2023/incorrect.png" alt="不正解"></h2>
						<h3 class="lp__quiz-modal--heading">答え</h3>
						<p class="lp__quiz-modal--answer"><em>{{ computedTodaysQuiz.a === 1 ? 'A' : computedTodaysQuiz.a === 2 ? 'B' : 'C' }}</em><span>{{ computedTodaysQuiz.choice[computedTodaysQuiz.a - 1] }}</span></p>
						<p class="lp__quiz-modal--discription">{{ computedTodaysQuiz.discription }}</p>
					</div>
					<div class="lp__quiz-modal--button">
						<a @click="isVisiableAnswerModal = false">
							<ons-icon icon="md-close" />表示を閉じる
						</a>
					</div>
				</div>
			</aside>
		</transition>
		<!-- /クイズ答えモーダル -->
	</section>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'
import Swiper from 'swiper'
import moment from 'moment'
moment.locale('ja')

export default {
	name: 'LpSpring2023',
	components: {
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			$html: null,
			answer: 0,	// 回答1～3のNumberが格納される
			modalType: 0,	// 春の5Kモーダル1～5のNumberが格納される
			isVisiableAnswerModal: false,
			isVisibleModal: false,
			pageTopObserver: null,
			sitecheck: null
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid']),

		computedTodaysQuiz () {
			const today = this.sitecheck ? this.sitecheck : moment().format('MMDD')
			// console.log(today)
			const objQuiz = {	// デフォルトは1日の問題
				q: '春の不調の原因になる「春の５K」とは、乾燥、花粉、強風、黄砂、もう一つは？',
				choice: ['高気圧', '寒暖差', '寒冷前線'],
				a: 2,
				discription: '春の５Ｋとは、寒暖差(Kandansa)のようにアルファベットにしたときに頭文字が全てＫで始まる注意が必要なこと。4月は寒暖差が大きい時期で初夏の陽気になったすぐ後に、冬の寒さに逆戻りすることもあるため、体のリズムが崩れやすくなります。'
			}

			// 今日の問題
			switch (today) {
				case '0402':
					objQuiz.q = '花粉の飛ぶ量が増えるのは一日の中でいつ頃？正しい組み合わせは？'
					objQuiz.choice = ['朝と昼前後', '朝と夕方', '昼前後と夕方']
					objQuiz.a = 3
					objQuiz.discription = '花粉は、気温が上がって飛びやすくなる昼前後と、気温が下がって上空の花粉が落ちてくる夕方頃(日没前後)に多く飛散します。気温が上がる前の午前10時頃までは花粉は少ない傾向があります。'
					break
				case '0403':
					objQuiz.q = '紫外線はUV-A,UV-B,UV-Cの3種類に分けられます。しわやたるみの主な原因になるのはどれ？'
					objQuiz.choice = ['UV-A', 'UV-B', 'UV-C']
					objQuiz.a = 1
					objQuiz.discription = 'UV-Aは肌の奥深くまで届いてコラーゲンにダメージを与え、しわやたるみの原因になります。UV-BはUV-Aよりも強いエネルギーで主にシミや皮膚がんなどの原因となります。UV-Cはオゾン層でカットされ、地上には届きません。'
					break
				case '0404':
					objQuiz.q = '全国的に気温が上昇する気圧配置は次のうち、どれ？'
					objQuiz.choice = ['東高西低', '西高東低', '南高北低']
					objQuiz.a = 3
					objQuiz.discription = '日本の南に高気圧、北に低気圧がある時は、高気圧から低気圧に向かって南から南西の暖かい風が流れ込むため、ほぼ全国で気温が上昇します。南高北低の気圧配置になると全国的にポカポカの陽気になる場合が多く、軽やかな服装のおしゃれを楽しめます。'
					break
				case '0405':
					objQuiz.q = 'きちんととめてないと洗濯物が飛ばされてしまう恐れがある風速はどれくらい？'
					objQuiz.choice = ['5メートル以上', '10メートル以上', '15メートル以上']
					objQuiz.a = 1
					objQuiz.discription = '軽い旗がはためくくらいの平均風速5メートルでも、洗濯物はしっかりとめておかないと、飛ばされてしまうことがあります。平均風速10メートル前後まで強まる時は洗濯物は室内干しがオススメ。'
					break
				case '0406':
					objQuiz.q = 'サクラが散り始めるサインは次のうち、どれ？'
					objQuiz.choice = ['花の中心が赤くなる', '花びらに縞模様が入る', '葉っぱが色付く']
					objQuiz.a = 1
					objQuiz.discription = '咲き始めから数日間は花の中心部は緑色ですが、もうすぐ散る頃の花は中心部が赤くなっていきます。赤が目立つようになると数日以内に散ってしまうので、お花見は早めに済ませると良さそうです。'
					break
				case '0407':
					objQuiz.q = '花粉症予防の3大原則とは、吸わない。触れない。もう一つは？'
					objQuiz.choice = ['外出しない', '持ち込まない', '踏みつけない']
					objQuiz.a = 2
					objQuiz.discription = '花粉症予防の3大原則は、①花粉を吸わない、②花粉に触れない、③花粉を持ち込まないの3つです。室内に持ち込まないように服についた花粉をよく払ってから家に入ることや帰宅後出来るだけ早く入浴して、体や髪に付着した花粉を洗い流すこともオススメ。'
					break
				case '0408':
					objQuiz.q = 'シーズン最後に降る雪（いわゆる”なごり雪”）は、札幌では平年いつ頃？'
					objQuiz.choice = ['4月2日', '4月10日', '4月19日']
					objQuiz.a = 3
					objQuiz.discription = '札幌でシーズン最後に降る平年日（雪の終日）は4月19日です。4月2日は根雪の終わる日で、4月10日は平年の最後に積雪する日です。4月に入っても、寒の戻りで北日本は雪の降る日もあるのです。'
					break
				case '0409':
					objQuiz.q = '国内の観測史上最も低い湿度は何％？'
					objQuiz.choice = ['10％', '5％', '0％']
					objQuiz.a = 3
					objQuiz.discription = '2005年4月9日に岐阜県高山市では最小湿度0％を記録しました。この日は日本海からの北西風が白山連峰を超える時にカラカラに乾いた風となって湿度が急激に下がりました。'
					break
				case '0410':
					objQuiz.q = '春の空が霞んで見える原因の一つ「黄砂」は、どこから飛んで来る？'
					objQuiz.choice = ['サハラ砂漠', 'アラビア砂漠', 'ゴビ砂漠']
					objQuiz.a = 3
					objQuiz.discription = '黄砂とは、東アジアの砂漠域(ゴビ砂漠、タクラマカン砂漠など)や黄土地帯から強風により吹き上げられた多量の砂やちりが、上空の風によって運ばれ、大気中に浮遊する現象。日本では4月をピークに春に多く、空が黄色っぽくに煙ることがあります。'
					break
				case '0411':
					objQuiz.q = '紫外線の強さを示す世界標準指標を何という？'
					objQuiz.choice = ['UVインデックス', 'UVパラメーター', 'UVメトリクス']
					objQuiz.a = 1
					objQuiz.discription = 'UVインデックスとは紫外線の強さによる人の健康に及ぼす度合いを数値化したもの。世界保健機関(WHO)はUVインデックスによる対策を推奨していて、頭痛ーるの紫外線指数はUVインデックスを元に計算しています。'
					break
				case '0412':
					objQuiz.q = '桜が青森で開花してから、函館で開花するまでにかかる日数はどれくらい？'
					objQuiz.choice = ['3日', '6日', '10日']
					objQuiz.a = 2
					objQuiz.discription = '青森で開花後、津軽海峡を越えて、函館で開花するまでの日数は2001年以降の平均で約6日。1週間はかからない年が多いです。一番遅い年は10日後、早い年は2日後に開花したことがあります。'
					break
				case '0413':
					objQuiz.q = '花粉が最も付着しやすい服の素材は次のうちどれ？'
					objQuiz.choice = ['ウール', 'ポリエステル', 'シルク']
					objQuiz.a = 1
					objQuiz.discription = '最も花粉が付きやすい服の素材はウールです。綿や、ポリエステルなどの化学繊維は比較的付着しにくい傾向があります。花粉が多く飛ぶ日はナイロンなど表面がツルツルした花粉が付きにくい素材の服装を選ぶのも良いでしょう。'
					break
				case '0414':
					objQuiz.q = 'タンポポの花が開閉するのに、最も関係のある気象要素は何？'
					objQuiz.choice = ['気温', '湿度', '日ざし']
					objQuiz.a = 3
					objQuiz.discription = 'タンポポの花は、日ざしが出ると花が開き、日が陰ると閉じる傾向があり、光に反応して花が開閉します。このため、雨の日には閉じている場合もあります。'
					break
				case '0415':
					objQuiz.q = '「霧」と「もや」の違いは何でしょう？'
					objQuiz.choice = ['出現する場所', '見通せる距離', '出現する時間']
					objQuiz.a = 2
					objQuiz.discription = '見通せる距離が1㎞未満を「霧」、1㎞以上を「もや」と呼びます。朝の冷え込みが強まる日などに霧が発生すると、大気中の汚れが浮遊する場合もあるので気を付けましょう。'
					break
				case '0416':
					objQuiz.q = '人の健康に快適な湿度は何％くらい？'
					objQuiz.choice = ['35～45％', '45～55％', '55～65％']
					objQuiz.a = 2
					objQuiz.discription = '40％を下回ると、喉などの粘膜のバリア機能が低下しやすくなり、お肌や眼なども乾きやすくなります。一方、60%を上回ると湿気で、汗が蒸発しずらくなり、熱がこもりやすくなったり、カビが発生しやすくなります。50％前後が快適ゾーンです。'
					break
				case '0417':
					objQuiz.q = '花粉が飛ぶ量が増える気象条件で正しいのはどれ？'
					objQuiz.choice = ['雨の前日', '雨の日', '雨の翌日']
					objQuiz.a = 3
					objQuiz.discription = '雨の日は花粉はほとんど飛ばなくなるため、雨上がりの翌日には丸2日分の花粉が飛散する場合が多く、花粉の飛散量は急増する傾向があります。'
					break
				case '0418':
					objQuiz.q = '停めている自転車が横倒しになる風速はどれくらい？'
					objQuiz.choice = ['5メートル前後', '10メートル前後', '15メートル前後']
					objQuiz.a = 2
					objQuiz.discription = '風速が10メートル前後になると停めている自転車が横倒しになる場合があります。日最大風速10メートル以上の日を「強風日数」といい、4月は多くの地域で強風日数が最も多い時期です。'
					break
				case '0419':
					objQuiz.q = '次のうち、春の代表的な季語どれ？'
					objQuiz.choice = ['うららか', 'すがすがしい', 'さわやか']
					objQuiz.a = 1
					objQuiz.discription = '「うららか(麗らか)」が春の心地よさを表す代表的な季語です。すがすがしい(清々しい)は初夏の陽気に使う季語、さわやかは秋の澄み切った青空の日などに使う季語です。'
					break
				case '0420':
					objQuiz.q = '朝に虹が見えたら、その後の天気はどうなる？'
					objQuiz.choice = ['晴れ', 'くもり', '雨']
					objQuiz.a = 3
					objQuiz.discription = '虹は太陽を背にして、雨雲のある方向を見た時に、雨粒に太陽の光が屈折反射して7色のプリズムとして見える現象です。朝の太陽は東から昇るため、その反対の西側にある雨雲が偏西風に流されてやってくる場合が多く、朝の虹の後は雨になるのです。'
					break
				case '0421':
					objQuiz.q = 'PM2.5は微粒子の大気汚染物質などによる健康被害が心配ですが、粒子はどれくらい小さい？'
					objQuiz.choice = ['髪の太さの1/30', '花粉と同じくらい', '赤血球の半分']
					objQuiz.a = 1
					objQuiz.discription = '直径が2.5㎛以下のPM2.5は、髪の毛の太さの約30分の1以下と非常に小さい粒子です。このため、細い気管支や肺の奥まで入りやすく、ぜん息や気管支炎などの呼吸器系の疾患への影響の他、肺がんリスクの上昇や循環器系への影響も懸念されています。'
					break
				case '0422':
					objQuiz.q = '春に人間が本能的に欲するといわれる味覚は何？'
					objQuiz.choice = ['甘味', '苦味', '旨味']
					objQuiz.a = 2
					objQuiz.discription = '人間の体は春～夏にかけて気温が上がっていく時期に苦味や酸味を求める傾向があります。「春の皿には苦味を盛れ」ということわざがあるように、菜の花やふきのとうなどの春野菜には、苦味のあるものも多く、旬のものを食べることは健康維持に大切です。'
					break
				case '0423':
					objQuiz.q = '紫外線の強さは快晴の日を100％とした時に、くもりの日は何％の強さになる？'
					objQuiz.choice = ['90％', '60％', '30％']
					objQuiz.a = 2
					objQuiz.discription = '雲によって、紫外線が地上に届く強さは少し弱まりますが、曇りの時でも快晴の60%の強さで到達します。また、薄曇りの場合は、90％も届くため、しっかりと紫外線対策が必要です。'
					break
				case '0424':
					objQuiz.q = '急激な気温の低下をもたらすのは、次のうちどれが通過した時？'
					objQuiz.choice = ['閉塞前線', '温暖前線', '寒冷前線']
					objQuiz.a = 3
					objQuiz.discription = '寒冷前線は、通過前の東側には暖気、通過後の西側には寒気があって、その境目にできる前線です。寒冷前線の通過に伴い、強い雨が降るとともに、気温が急激に低下、気圧は急激に上昇するため、寒暖差や気圧変化で体調を崩しやすくなる場合があります。'
					break
				case '0425':
					objQuiz.q = '光を浴びることで脳から分泌される通称「幸せのホルモン」は次のうちどれ？'
					objQuiz.choice = ['アドレナリン', 'セロトニン', 'ドーパミン']
					objQuiz.a = 2
					objQuiz.discription = 'セロトニンは、心や自律神経のバランスを整え、頭をスッキリさせる効果のある神経伝達物質です。光を浴びる量が少ないとセロトニンの分泌量が減る場合があります。紫外線が弱い朝の太陽光を適度に浴びて、散歩をすると心身の健康維持や安眠にも効果的です。'
					break
				case '0426':
					objQuiz.q = 'チューリップの花が大きく開く気温は何℃？'
					objQuiz.choice = ['10℃', '15℃', '20℃']
					objQuiz.a = 3
					objQuiz.discription = 'チューリップの花は気温に反応して開閉します。15℃前後になると開き始めて、20℃くらいで大きく開くようになります。反対に10度前後まで下がると閉じたままになります。'
					break
				case '0427':
					objQuiz.q = '山を吹き降りた風の影響で気温が高くなることがあります。この現象を何という？'
					objQuiz.choice = ['ブロッケン現象', 'ラニーニャ現象', 'フェーン現象']
					objQuiz.a = 3
					objQuiz.discription = 'フェーン現象とは、風上側から山を上った風が、山を越えたあと、高温で乾燥した風となり、風下側に吹き降ろす現象のこと。フェーン現象が発生すると、急激な気温上昇に加え、強い乾燥と風を伴う場合が多く、火災や体調不良の原因になることもあります。'
					break
				case '0428':
					objQuiz.q = '鯉のぼりが元気よく泳ぐ時の風の強さはどれくらい？'
					objQuiz.choice = ['3メートル', '5メートル', '10メートル']
					objQuiz.a = 2
					objQuiz.discription = '鯉のぼりは風速が3メートル前後で泳ぎ始め、5メートルくらいでほぼ真横に元気に泳ぎ始めます。風速が10メートル以上になると暴れるように泳ぐようになるため、安全のためおろした方が良い強さです。'
					break
				case '0429':
					objQuiz.q = 'いくつもの高気圧が東西に連なるような気圧配置のことを何という？'
					objQuiz.choice = ['連続高気圧', '帯状高気圧', 'ブロッキング高気圧']
					objQuiz.a = 2
					objQuiz.discription = '一つの高気圧だけではなくて、いくつもの高気圧が東西に帯状に連なるような気圧配置を帯状高気圧といい、春に出現することが多いです。帯状高気圧に覆われると晴れが3日以上長持ちします。'
					break
				case '0430':
					objQuiz.q = '紫外線対策に良い代表的なビタミンは３つ。ビタミンC、βカロテン(ビタミンA)、もう一つは何？'
					objQuiz.choice = ['ビタミンE', 'ビタミンB1', 'ビタミンD']
					objQuiz.a = 1
					objQuiz.discription = 'ビタミンC、βカロテン、ビタミンEの３つがUVケアに効果的な「抗酸化ビタミントリオ」と呼ばれています。ビタミンEは、ホウレン草やカボチャ、キウイなどに豊富でツナ缶などでも気軽に摂取できます。'
					break
				default:
					objQuiz.q = '春の不調の原因になる「春の５K」とは、乾燥、花粉、強風、黄砂、もう一つは？'
					objQuiz.choice = ['高気圧', '寒暖差', '寒冷前線']
					objQuiz.a = 2
					objQuiz.discription = '春の５Ｋとは、寒暖差(Kandansa)のようにアルファベットにしたときに頭文字が全てＫで始まる注意が必要なこと。4月は寒暖差が大きい時期で初夏の陽気になったすぐ後に、冬の寒さに逆戻りすることもあるため、体のリズムが崩れやすくなります。'
					break
			}

			return objQuiz
		}
	},
	created () {
	},
	mounted () {
		// 横幅に合わせてフォントサイズや余白をレスポンシブに可変する対応
		const propWidth = document.body.offsetWidth
		const style = propWidth <= 640 ? 'font-size: 16px; font-size: 4.267vw;' : 'font-size: 27px;'
		this.$html = document.getElementsByTagName('html')[0]
		this.$html.setAttribute('style', style)

		// ページ遷移後のアンカーリンク
		if (this.$route.hash) window.addEventListener('load', this.hashAnchorLink)

		// Swiper初期化処理
		this.onSwiper()

		// ページTOPに戻るボタン表示の処理
		const $target = document.getElementById('js_observer')
		const $pagetopBtn = document.getElementById('js_pagetop_btn')
		this.pageTopObserver = new IntersectionObserver(entries => {
			const entry = entries[0]
			if (entry.isIntersecting) {
				$pagetopBtn.classList.remove('is-show')
			} else {
				$pagetopBtn.classList.add('is-show')
			}
		})
		this.pageTopObserver.observe($target)

		// 未来日確認用
		const sitecheck = this.$route.query.sitecheck
		if (sitecheck) this.sitecheck = sitecheck
	},
	beforeDestroy () {
		window.removeEventListener('load', this.hashAnchorLink)
		this.$html.removeAttribute('style')
		this.pageTopObserver.disconnect()
	},
	methods: {
		// ページスクロール用の関数
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		},

		// ページ遷移後のアンカーリンク
		// NOTE: 今後統一する予定
		hashAnchorLink () {
			const $elem = document.querySelector(this.$route.hash)
			if ($elem) $elem.scrollIntoView ? $elem.scrollIntoView({ behavior: 'smooth', block: 'start' }) : scrollTo(0, $elem.getBoundingClientRect().top)
		},

		// モーダルのリンククリックイベント
		clickModalLink (value, event) {
			event.preventDefault()
			this.isVisibleModal = value > 0
			if (this.isVisibleModal) this.modalType = value
		},

		// Swiperの処理
		onSwiper () {
			const swiperConstructor = () => {
				const $swiper = document.querySelectorAll('.js_swiper')
				const $next = document.querySelectorAll('.js_swiper_next')
				const $prev = document.querySelectorAll('.js_swiper_prev')
				const $pagination = document.querySelectorAll('.js_swiper_pagination')
				let initSwiper
				for (let i = 0, len = $swiper.length; i < len; i++) {
					$swiper[i].className += i
					$next[i].className += i
					$prev[i].className += i
					$pagination[i].className += i
					initSwiper = new Swiper('.js_swiper' + i, {
						slidesPerView: 1,
						spaceBetween: 10,
						observer: true,
						observeParents: true,
						navigation: {
							nextEl: '.js_swiper_next' + i,
							prevEl: '.js_swiper_prev' + i
						},
						pagination: {
							el: '.js_swiper_pagination' + i
						}
					})
				}
				return initSwiper
			}
			swiperConstructor()
		}
	}
}
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/variable";
@import "~swiper/css/swiper.min.css";

$lp-primary-color: #FF5A7C;
$lp-secondary-color: #43C2A0;
$lp-background-color: #FEE5E9;
$lp-border-color: #D5B378;
$lp-title-color: #431403;
$lp-contents-background-color: #F6EDDF;

$spacing-7: 0.437rem;
$spacing-9: 0.562rem;
$spacing-12: 0.75rem;
$spacing-15: 0.937rem;
$spacing-17: 1.062rem;
$spacing-23: 1.437rem;
$spacing-24: 1.5rem;
$spacing-28: 1.75rem;
$spacing-37: 2.312rem;
$spacing-42: 2.625rem;
$spacing-62: 3.875rem;
$spacing-70: 4.375rem;
$spacing-84: 5.25rem;

$font-size-26: 1.625rem;
$font-size-30: 1.875rem;
$font-size-36: 2.25rem;

$line-height-1_375: 1.375;

.lp {
	font-family: $lp-family-primary;
	max-width: 640px;
	margin: 0 auto;
	color: $text-primary;
	font-size: $font-size-16;
	line-height: $line-height-1_375;

	ul, li { list-style: none; }

	em { font-style: normal; }

	img {
		width: 100%;
		display: block;
	}

	/* ページ下部に吸着するボタン */
	.sticky-bottom-appdownload {
		margin: 0;
		padding: $spacing-8 $spacing-14;
		box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.1);
	}

	&__inner { background-color: $lp-background-color; }

	/* ヘッダー */
	&__header {
		margin: $spacing-10 auto 0;
		padding: 0 $spacing-14;
		box-sizing: border-box;

		&--logo {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin: $spacing-2 auto 0;
			font-size: $font-size-10;

			& > img {
				width: 25.9366%;
				margin-left: $spacing-4;
			}
		}
	}

	/* イントロ */
	&__intro {
		margin: $spacing-10 auto 0;
		padding: 0 $spacing-14;

		& > em {
			font-family: $lp-family-secondary;
			color: $lp-primary-color;
		}
	}

	/* ナビ */
	&__nav {
		margin: $spacing-20 auto 0;

		&--title {
			font-family: $lp-family-secondary;
			padding: $spacing-8 0;
			margin: 0;
			background-color: $lp-secondary-color;
			font-size: $font-size-26;
			color: $text-seconday;
			text-align: center;
			font-weight: normal;
		}

		&--list {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0 auto;
			padding: $spacing-18 $spacing-14;

			li {
				width: 31.124%;
			}
		}
	}

	/* セクション共通 */
	&__contents {
		$parent: &;

		position: relative;
		padding: $spacing-37 0 $spacing-24;
		margin: $spacing-62 auto 0;
		background: url(~@/assets/img/lp_spring2023/bg_top.png) no-repeat 0 0;
		background-size: 100% auto;
		background-color: $background-primary;
		border-bottom: 3px solid $lp-border-color;

		&::before {
			content:'';
			display: block;
			position: absolute;
			top: -$spacing-37;
			left: 50%;
			transform: translateX(-50%);
			width: 34.6667%;
			padding-top: 19.7334%;
		}

		&--title {
			position: relative;
			margin: $spacing-16 $spacing-14 0;
			padding: 0;
			font-family: $lp-family-tertiary;
			text-align: center;
			font-weight: normal;

			& > span,
			& > em { display: block; }

			& > span {
				position: absolute;
				top: -$spacing-4;
				left: 50%;
				transform: translateX(-50%);
				font-size: $font-size-18;
				color: $lp-primary-color;
				line-height: $line-height-1;
			}

			& > em {
				width: 100%;
				font-size: $font-size-30;
				color: $lp-title-color;
				line-height: $line-height-1;
			}
		}

		@for $i from 1 through 3 {
			&:nth-of-type(#{$i}) {
				&::before {
					background: url(~@/assets/img/lp_spring2023/step0#{$i}.png) no-repeat 0 0;
					background-size: 100% auto;
				}

				@if $i <= 2 {
					#{$parent}--title {
						padding-top: 23.0548%;
						background: url(~@/assets/img/lp_spring2023/title_bg0#{$i}.png) no-repeat 0 0;
						background-size: 100% auto;

						& > em {
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);
						}
					}
				}
				@else {
					#{$parent}--title {
						margin: $spacing-12 $spacing-14 0;
						background: url(~@/assets/img/lp_spring2023/title_bg0#{$i}.png) no-repeat 0 50%;
						background-size: 100% auto;

						& > em { font-size: $font-size-16; }
					}
				}
			}
		}

		&--text {
			margin: $spacing-4 auto 0;
			padding: 0 $spacing-14;
		}
	}

	&__nav + &__contents {
		margin: $spacing-42 auto 0;
		padding-bottom: $spacing-30;
	}

	/* クイズ */
	&__quiz {
		position: relative;
		margin: $spacing-28 $spacing-14 0;
		padding: $spacing-24 $spacing-24 $spacing-20;
		background-color: $lp-contents-background-color;
		border-radius: $spacing-10;

		&--title {
			position: absolute;
			top: -$spacing-15;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 57.061%;
			margin: 0 auto;
			padding: $spacing-4 0;
			background-color: $background-primary;
			font-family: $lp-family-tertiary;
			font-size: $font-size-18;
			line-height: $line-height-1;
			border-radius: $spacing-16;

			& > svg,
			& > span { display: block; }

			& > svg { width: 12.681%; }
			& > span { margin-left: $spacing-4; }
		}

		&--text { margin: $spacing-12 0 0; }

		&--list {
			width: 93.6455%;
			margin: $spacing-18 auto 0;
			padding: 0;
		}

		&--item + &--item { margin: $spacing-10 0 0; }
		&--item {
			display: flex;
			align-items: center;
			padding: $spacing-4 $spacing-12;
			background-color: $background-primary;
			border: 2px solid $lp-primary-color;
			border-radius: $spacing-23;

			& > em {
				display: block;
				width: 11.1112%;
				font-family: $lp-family-tertiary;
				font-size: $font-size-24;
				text-align: center;
				color: $lp-primary-color;
			}

			& > p {
				flex: 1;
				margin: 0 0 0 $spacing-8;
				padding: $spacing-2 0 $spacing-2 $spacing-20;
				font-family: $lp-family-secondary;
				border-left: 1px solid $lp-primary-color;
			}

			&.is-check { background-color: $lp-background-color; }
		}

		&--button {
			display: block;
			width: 100%;
			margin: $spacing-20 auto 0;
			padding: $spacing-12 0;
			background-color: $button-secondary;
			text-decoration: none;
			color: $text-seconday;
			font-size: $font-size-18;
			text-align: center;
			line-height: $line-height-1;
			border-radius: $spacing-6;
			pointer-events: none;

			&.is-active {
				background-color: $lp-secondary-color;
				pointer-events: auto;
			}
		}
	}

	/* 春の5K */
	&__5k {
		margin: $spacing-18 auto 0;
		padding: 0 $spacing-14;

		&--title {
			margin: 0;
			font-family: $lp-family-tertiary;
			color: $lp-secondary-color;
			font-weight: normal;
			font-size: $font-size-16;
			text-align: center;
		}

		&--list {
			position: relative;
			padding: 96.83% 0 0 0;
			margin: $spacing-10 auto 0;

			&::before {
				content: '';
				position: absolute;
				width: 93.0836%;
				padding-top: 89.3372%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background: url(~@/assets/img/lp_spring2023/5k_bg.png) no-repeat 0 0;
				background-size: 100% auto;
			}
		}

		&--item {
			position: absolute;
			width: 31.7003%;

			&:nth-of-type(1) {
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			}

			&:nth-of-type(2) {
				top: 27.381%;
				left: 0;
			}

			&:nth-of-type(3) {
				top: 27.381%;
				right: 0;
			}

			&:nth-of-type(4) {
				bottom: 0;
				left: 13.5447%;
			}

			&:nth-of-type(5) {
				bottom: 0;
				left: 55.0433%;
			}
		}

		&--outro {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 76.3689%;
			margin: $spacing-10 auto 0;

			& > img {
				display: block;
				width: 38.4906%;
			}

			& > p {
				position: relative;
				width: 59.2453%;
				margin: 0 0 0 $spacing-6;
				padding-top: 22.6416%;
				background: url(~@/assets/img/lp_spring2023/fukidashi01.png) no-repeat 0 0;
				background-size: 100% auto;

				& > span {
					position: absolute;
					top: 50%;
					right: $spacing-16;
					transform: translateY(-50%);
					font-size: $font-size-14;
				}
			}
		}
	}

	/* 検定 */
	&__inspection {
		margin: $spacing-14 auto 0;
		padding: 0 $spacing-14;

		&--title { margin: 0; }

		&--text { margin: $spacing-14 auto 0; }

		&--quiz {
			position: relative;
			margin: $spacing-23 auto 0;
			padding: $spacing-10 $spacing-20 $spacing-37;
			background: url(~@/assets/img/lp_spring2023/inspection_bg_top.png) no-repeat 0 0,
							url(~@/assets/img/lp_spring2023/inspection_bg_bottom.png) no-repeat 0 100%,
							url(~@/assets/img/lp_spring2023/inspection_bg.png) repeat 0 0;
			background-size: 100% auto, 100% auto, 100% auto;
		}

		&--heading {
			margin: 0;
			padding: 0 0 $spacing-10;
			font-family: $lp-family-tertiary;
			font-size: $font-size-36;
			font-weight: normal;
			color: $lp-primary-color;
			line-height: $line-height;
			text-align: center;
			border-bottom: 1px dashed $lp-primary-color;
		}

		&--question {
			margin: $spacing-14 auto 0;
			font-family: $lp-family-secondary;
			font-size: $font-size-18;
		}

		&--button {
			display: block;
			width: 97.36%;
			margin: $spacing-16 auto 0;
			padding: $spacing-14 0;
			background-color: $lp-secondary-color;
			text-decoration: none;
			color: $text-seconday;
			font-size: $font-size-18;
			text-align: center;
			line-height: $line-height-1;
			border-radius: $spacing-6;
		}
	}

	&__calendar {
		margin: $spacing-23 auto 0;
		padding: 0 $spacing-14;

		&--title {
			position: relative;
			margin: 0;
			padding-top: 8.00296%;
			font-family: $lp-family-tertiary;
			color: $lp-primary-color;
			font-weight: normal;
			font-size: $font-size-18;
			text-align: center;
			background: url(~@/assets/img/lp_spring2023/calendar_title_bg.png) no-repeat 0 0;
			background-size: 100% auto;

			& > span {
				position: absolute;
				display: block;
				width: 100%;
				top: calc(50% - #{$spacing-2});
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		&--text { margin: $spacing-12 auto 0; }

		&--sample {
			position: relative;
			margin: $spacing-6 $spacing-16 0;
			padding-top: 116.4266%;
			background: url(~@/assets/img/lp_spring2023/top_calendar.png) no-repeat 0 0;
			background-size: 100% auto;
		}

		&--charactor {
			position: absolute;
			bottom: 0;
			right: $spacing-17;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 69.8413%;

			& > img {
				display: block;
				width: 40%;
			}

			& > p {
				position: relative;
				width: 60%;
				margin: 0;
				padding-top: 28.1819%;
				background: url(~@/assets/img/lp_spring2023/fukidashi02.png) no-repeat 0 0;
				background-size: 100% auto;

				& > span {
					position: absolute;
					top: 50%;
					left: $spacing-12;
					transform: translateY(-50%);
					font-size: $font-size-14;
				}
			}
		}
	}

	/* アウトロ */
	&__outro {
		position: relative;
		margin: $spacing-20 auto 0;
		padding: $spacing-40 0 $spacing-30;
		background-color: $background-primary;

		&--button {
			font-family: $lp-family-secondary;
			display: block;
			width: 61.3334%;
			margin: 0 auto;
			padding: $spacing-10 0;
			border-radius: $spacing-26;
			font-weight: normal;
			text-align: center;
			text-decoration: none;
			box-sizing: border-box;
			color: $text-seconday;
			font-size: $font-size-24;
			background-color: $primary;
		}
	}

	/* ページTOPに戻るボタン */
	&__pagetop {
		position: sticky;
		max-width: 640px;
		width: 100%;
		margin: -$spacing-84 auto 0;
		bottom: $spacing-70;
		opacity: 0;
		transition: all .3s ease-in;
		pointer-events: none;

		&.is-show { opacity: 1; }

		&--link {
			display: block;
			width: 13.0667%;
			line-height: $line-height-1;
			padding-right: $spacing-14;
			margin-left: auto;
			pointer-events: auto;

			& > img { display: block; }
		}
	}

	// モーダル
	&__modal {
		position: fixed;
		height: 100vh;

		/deep/ .modal__content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&--inner {
			margin: 0 auto;
			width: 347px;
			background-color: $background-primary;

			@media (max-width: (374px)) {
				width: 310px;
			}
		}

		&--body { position: relative; }

		&--button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 35px;
			background: $background-secondary;
			box-sizing: border-box;

			& > a {
				color: $text-primary;
				font-size: $font-size-12;

				@media screen and (min-width: 376px) {
					font-size: 12px;	// タブレットサイズ用（横幅375px以上決め打ち）にpxで指定
				}

				ons-icon {
					font-size: $font-size-16;
					vertical-align: middle;
					margin-right: $spacing-10;

					@media screen and (min-width: 376px) {
						font-size: 16px;	// タブレットサイズ用（横幅375px以上決め打ち）にpxで指定
					}
				}
			}
		}
	}

	// クイズ回答モーダル
	&__quiz-modal {
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		background: rgba($_black, .7);
		overflow: hidden;
		z-index: 9999;

		&--inner {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 92.5334%;
			max-width: 600px;
			box-sizing: border-box;
			background-color: $background-primary;
		}

		&--result {
			margin: $spacing-20;
			padding: $spacing-7 $spacing-7 $spacing-30;
			border: 3px solid $background-list;
			border-radius: $spacing-10;
		}

		&--title { margin: 0; }

		&--heading {
			margin: 0;
			font-family: $lp-family-tertiary;
			font-size: $font-size-18;
			color: $lp-title-color;
			text-align: center;
			line-height: $line-height-1;
		}

		&--answer {
			display: flex;
			align-items: center;
			padding: $spacing-6 $spacing-12;
			margin: $spacing-4 $spacing-4 0;
			background-color: $background-primary;
			border: 2px solid $lp-primary-color;
			border-radius: $spacing-23;

			& > em,
			& > span { display: block; }

			& > em {
				width: 11.1554%;
				font-family: $lp-family-tertiary;
				font-size: $font-size-24;
				text-align: center;
				color: $lp-primary-color;
			}

			& > span {
				flex: 1;
				margin: 0 0 0 $spacing-8;
				padding: $spacing-2 0 $spacing-2 $spacing-20;
				font-family: $lp-family-secondary;
				border-left: 1px solid $lp-primary-color;
			}
		}

		&--discription {
			margin: $spacing-14 $spacing-4 0;
			font-size: $font-size-14;
		}

		&--button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			padding: $spacing-9 0;
			background: $background-secondary;
			line-height: $line-height-1;

			& > a {
				font-size: $font-size-12;

				ons-icon {
					font-size: $font-size-16;
					vertical-align: middle;
					margin-right: $spacing-10;
				}
			}
		}
	}
}

// swiper
.slider {
	padding-top: $spacing-20;

	&__nav {
		.swiper-button-prev,
		.swiper-button-next {
			margin-top: -40px;
			width: 40px;
			height: 40px;

			&:before,
			&:after { display: none; }

			&.swiper-button-disabled { opacity: 0; }
		}

		.swiper-button-prev {
			left: 10px;
			background: url(~@/assets/img/lp_heatshock2023/slider_prev.png) center center / 100% auto no-repeat;
		}

		.swiper-button-next {
			right: 10px;
			background: url(~@/assets/img/lp_heatshock2023/slider_next.png) center center / 100% auto no-repeat;
		}
	}

	&__container {
		margin: 0;

		@media (max-width: (374px)) {
			height: 400px;
		}
		@media (max-width: (320px)) {	// NOTE: 今回モーダルが縦長なので縦が小さい画面を考慮
			height: 360px;
		}
	}

	.swiper-wrapper {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.swiper-pagination-bullets {
		padding: $spacing-14 0;
		display: flex;
		align-items: center;
		justify-content: center;

		@media screen and (min-width: 376px) {
			padding: 14px 0;	// タブレットサイズ用（横幅375px以上決め打ち）にpxで指定
		}

		/deep/ .swiper-pagination-bullet {
			margin: 0 $spacing-10;
			width: 5px;
			height: 5px;
			background: $button-secondary;
			opacity: 1;

			@media screen and (min-width: 376px) {
				margin: 0 10px;	// タブレットサイズ用（横幅375px以上決め打ち）にpxで指定
			}
		}

		/deep/ .swiper-pagination-bullet-active { background: $text-primary; }
	}
}

// アニメーション
.fade-enter-active, .fade-leave-active {
	transition: opacity .6s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

// sprite
.sprite {
	display: block;
	width: 308px;
	height: 447px;
	margin: 0 auto;
	background-image: url(~@/assets/img/lp_spring2023/sprite.png);
	background-size: (626px / 2) (10848px / 2);
	background-repeat: no-repeat;

	@media (max-width: (374px)) {
		transform: scale(.89286);
		transform-origin: top center;
	}
	@media (max-width: (320px)) {	// NOTE: 今回モーダルが縦長なので縦が小さい画面を考慮
		transform: scale(.80692);
		transform-origin: top center;
	}

	&.app_dl {
		width: (616px / 2);
		height: (894px / 2);
		background-position: (-5px / 2) (-5px / 2);
	}

	&.kafun_1 {
		width: (616px / 2);
		height: (894px / 2);
		background-position: (-5px / 2) (-909px / 2);
	}

	&.kafun_2 {
		width: (616px / 2);
		height: (894px / 2);
		background-position: (-5px / 2) (-1813px / 2);
	}

	&.kandansa_1 {
		width: (616px / 2);
		height: (894px / 2);
		background-position: (-5px / 2) (-2717px / 2);
	}

	&.kandansa_2 {
		width: (616px / 2);
		height: (894px / 2);
		background-position: (-5px / 2) (-3621px / 2);
	}

	&.kandansa_3 {
		width: (616px / 2);
		height: (894px / 2);
		background-position: (-5px / 2) (-4525px / 2);
	}

	&.kansou_1 {
		width: (616px / 2);
		height: (894px / 2);
		background-position: (-5px / 2) (-5429px / 2);
	}

	&.kansou_2 {
		width: (616px / 2);
		height: (894px / 2);
		background-position: (-5px / 2) (-6333px / 2);
	}

	&.kousa_1 {
		width: (616px / 2);
		height: (894px / 2);
		background-position: (-5px / 2) (-7237px / 2);
	}

	&.kousa_2 {
		width: (616px / 2);
		height: (894px / 2);
		background-position: (-5px / 2) (-8141px / 2);
	}

	&.kyoufuu_1 {
		width: (616px / 2);
		height: (894px / 2);
		background-position: (-5px / 2) (-9045px / 2);
	}

	&.kyoufuu_2 {
		width: (616px / 2);
		height: (894px / 2);
		background-position: (-5px / 2) (-9949px / 2);
	}
}
</style>
